import React from 'react';
import useTermsData from '../shared/Hooks/TermsData/useTermsData';
import Image from 'gatsby-image';
import {useBackgroundImage} from '../shared/Hooks/Background';

export const useData = () => {
  const cards = useTermsData();
  const backgroundImgs = useBackgroundImage();

  return {
    seo: {
      title: 'Ogólne Warunki Współpracy',
    },
    parallaxText: (
      <Image fixed={backgroundImgs.terms.childImageSharp.fixed}/>
    ),
    title: 'Ogólne Warunki Współpracy',
    topLayout: {
      title: <>Zasady współpracy</>,
      text: <>
        Zapraszamy do zapoznania się z warunkami współpracy z firmą ArtProgres. Poniżej znajdą Państwo wszelkie niezbędne informacje dotyczące współpracy oraz ochrony danych osobowych.
      </>,
    },
    cardContainer: {
      cards,
    },
  };
};
