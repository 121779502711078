import React from 'react';
import Terms from '../sections/Terms';
import {useData} from '../data/terms';

export default function Warunki() {
  const data = useData();

  return (
    <Terms data={data}/>
  );
}
