import {useTermsIcons} from './useTermsIcons';

const useTermsData = () => {
  const icons = useTermsIcons();
  const data = [
    {
      title: 'Ogólne Warunki Współpracy',
      icon: icons.comment,
      href: '/pdf/OWW-2022.pdf',
      downloadText: 'Pobierz',
    },
    {
      title: 'Formularz Reklamacyjny',
      icon: icons.pen,
      href: '/pdf/Formularz-reklamacyjny.pdf',
      downloadText: 'Pobierz',
    },
    {
      title: 'Informacje RODO',
      icon: icons.bag,
      href: '/pdf/Informacje-RODO.pdf',
      downloadText: 'Pobierz',
    },
  ];
  return data;
};
export default useTermsData;
